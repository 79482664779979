.TokenSelector2 {
  .Modal-backdrop {
    // background: transparent;
    z-index: 2;
  }
  .Modal-content {
    // width: 100% !important;
    // height: 100%;
    height: 600px;
    min-width: 360px;
  }

  .Modal-body {
    padding: 0 !important;
    margin: 0 !important;
  }

  .Modal-title-bar,
  .divider {
    display: none;
  }

  .dialogHeader {
    .desc {
      line-height: 20px;
    }
  }

  .backIcon,
  .coinIcon {
    display: block !important;
  }

  .TokenSelector-token-input-row {
    margin: 30px 40px;

    input {
      height: 60px;
    }
  }

  .tokenList {
    padding: 0 16px;
    overflow: hidden;
    flex: 1;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #fffd02;
    }
    .tokenItem {
      height: 76px;
      padding: 16px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
      &:hover {
        cursor: pointer;
      }
      &:last-child {
        border-bottom: transparent;
      }
    }
  }
}
