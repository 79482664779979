.infoBox {
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 8px;

  .infoTitle,
  .infoVal {
    grid-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }

  .infoTitle {
    border-radius: 8px 8px 0 0;
    height: 40px;
    padding-left: 0;
    background: rgba(255, 255, 255, 0.08);
  }

  .infoVal {
    min-height: 48px;
    padding-bottom: 18px;
    padding-top: 16px;
  }
}
