.header-view {
  .header {
    width: 100%;
    height: 70px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);

    .logo {
      margin-left: 16px;
    }

    .header-r {
      margin-right: 16px;
    }
  }
}

.menuList {
  margin-left: 60px;

  .menuItem {
    color: rgba(255, 255, 255, 0.54);
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 16px;
    font-weight: 500;

    &:hover {
      cursor: pointer;
      color: #fffd02;
    }
  }

  .menuItemActive {
    color: #fffd02;
    position: relative;

    // &::after {
    //   position: absolute;
    //   content: "";
    //   bottom: 0px;
    //   right: 0;
    //   left: 0;
    //   top: 0;
    //   margin: 0 auto;
    //   bottom: 4rem;
    //   height: 0.4rem;
    //   width: 2rem;
    //   transform: translateY(4.7rem);
    //   background: #FFFD02;
    // }
  }
}

@media only screen and (max-width: 768px) {
  .header-view {
    .header {
      .logo {
        margin-left: 6px;
      }

      .header-r {
        margin-right: 12px;
      }
    }
  }
}
