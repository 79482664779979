.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: 1.4rem;
  overflow: hidden;
  color: #9B96FF;
  /* border: 1px solid #9B96FF; */
  /* background: linear-gradient(0deg, #28229C, #28229C), #5E58DB;; */
  box-shadow: inset 0px 0px 30px 5px rgba(255, 255, 255, 0.01);
}

.Tab-option-icon {
  margin-right: 0.465rem;
  opacity: 0.7;
}

.Exchange-list-tabs {
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4.8rem;
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  justify-content: center;
  color: rgba(238, 242, 249, 0.5);
}

.tab_icon .icons>path {
  width: 24px;
  height: 24px;
  fill: rgba(238, 242, 249, 0.5);
}

.Short .icons>path {
  fill: #FA5542;
}

.Short {
  color: #FA5542 !important;
}

.Long .icons>path {
  fill: #FFF872;
}

.Long {
  color: #FFF872 !important;
  ;
}

.Swap .icons>path {
  fill: #00D2FC;
}

.Swap {
  color: #00D2FC !important;
  ;
}

.Tab.block .Tab-option:hover {
  color: rgba(255, 255, 255, 1);
  background: rgba(255, 255, 255, 0.05);
}

.Tab.block .Tab-option.Long {
  color: #FFF872;
}

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.05);
  font-weight: 700;
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline .Tab-option {
  cursor: pointer;

  gap: 1.6rem;
  font-size: 1.5rem;
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
}